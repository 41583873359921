import { atom } from 'jotai'

export interface BackstagePlay {
  back: boolean
  currentTime: number
}

export interface Video {
  courseVideoId?: number
  sort: number
  videoUrl: string | undefined
  cover: string | undefined
  title: string | undefined
  status: number // 2 锁定
}
export interface PreShow {
  name: string
  url: string
}

export interface Course {
  id: number
  title: string
  cover: string
  previewCover: string
  memo: string
  type: string
  payStatus: boolean
  videoList: Video[]
  preShow: PreShow[] | null
}

export const checkCourseState = atom<Course | null>(null)
export const checkVideoState = atom<Video | null>(null)
export const checkIndexState = atom<number>(-1)
export const minFloatState = atom<boolean>(false)
